import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '@organisms/layout'
import SEO from '@atoms/seo'
import Heading from '@particles/heading'
import Cards from '@molecules/cards'
import List from '@molecules/list'
import Pagination from '@atoms/pagination'
import Text from '@particles/text'
import ServiceLogo from '@atoms/service-logo'

import styled from 'styled-components'
import { media, useMedia } from '@breakpoints'

const AllServicesPageStyle = styled(Layout)`
  ${media.tablet`
    .side-grid {
      grid-template-columns: 2.5fr 4.2fr;
    }
  `}

  ${media.phablet`

    .side-grid {
      grid-template-columns: 1fr;
    }

  `}
`

const StyledServiceLogo = styled(ServiceLogo)`
  background: white;
  padding: 1rem;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.15));
`

const AllServicesPage = ({
  data,
  pageContext: {
    currentPage,
    numPages,
    relativePath,
    services,
    serviceName,
    logo
  },
  location
}) => {
  const metrics = data.metrics.edges || {}
  const isPhablet = useMedia('phablet')

  return (
    <AllServicesPageStyle location={location}>
      <SEO title={`${serviceName} Metrics`} location={location} />
      <Layout.Row center margin="T4 B4" wrap="true">
        <StyledServiceLogo src={logo} sm margin="R2" />
        <Heading h1>{`${serviceName} Metrics`}</Heading>
      </Layout.Row>
      <Layout.Grid
        className="side-grid"
        gridTemplate="1fr 4.3fr"
        gap={3.75}
      >
        <List title="Services" collapsed={isPhablet}>
          {services &&
            services.map((v, i) => {
              return (
                <List.Item
                  key={`${v.data}-${i}`}
                  suffix={v.number}
                  active={v.data === serviceName}
                  link={v.url}
                >
                  {v.data}
                </List.Item>
              )
            })}
        </List>
        <div>
          <Layout.Grid fixed={294} gap={1.25} margin="T0 L0">
            {metrics.map(({ node: {metric} }, i) => {
              if(!metric || metric.status !== "published") return
              return (
                <Cards.Metric
                  key={`${metric.name}-${i}`}
                  hover="true"
                  link={metric.slug}
                  header={metric.name}
                  auto="true"
                >
                  {metric.description && <Text>
                    {metric.description.substring(0, 60).trim()}...
                  </Text>}
                </Cards.Metric>
              )
            })}
          </Layout.Grid>
        </div>
      </Layout.Grid>
      <Layout.Grid margin="T4.375" gridTemplate="1fr 4.3fr" gap={3.75}>
        <span />
        <Pagination
          relativePath={relativePath}
          currentPage={currentPage}
          totalPages={numPages}
        />
      </Layout.Grid>
    </AllServicesPageStyle>
  )
}
AllServicesPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.any,
  location: PropTypes.any
}
export default AllServicesPage

export const pageQuery = graphql`
  query AllServicesQuery($skip: Int!, $limit: Int!, $filter: String!) {
    metrics: allInstantMetric(
      skip: $skip
      limit: $limit
      filter: {service: {service_key: {eq: $filter}}, metric: {status: {eq: "published"}}}
      sort: {fields: metric___name, order: ASC}
    ) {
      edges {
        node {
          metric {
            status
            directus_id
            name
            description
            slug
          }
        }
      }
    }
  }
`
